<template>
    <section class="shop-categories">
        <tool-bar v-bind:lang.sync="lang">
            Product Categories
            <template slot="buttons">
                <div class="level-item">
                    <button class="button"
                            v-on:click="openModal('add')">
                        <span class="icon is-small">
                            <font-awesome-icon icon="plus"></font-awesome-icon>
                        </span>
                        <span>Add a Category</span>
                    </button>
                </div>
                <div class="level-item">
                    <button class="button"
                            v-bind:class="{ 'is-loading': loading }"
                            v-bind:disabled="!edited"
                            v-on:click="save">
                        <span class="icon is-small">
                            <font-awesome-icon icon="save"></font-awesome-icon>
                        </span>
                        <span>Save</span>
                    </button>
                </div>
            </template>
        </tool-bar>

        <div class="content">
            <article class="message is-dark">
                <div class="message-body" style="background-color: rgba(255, 255, 255, 1)">
                    You can drag the category rows below to reorder them.
                </div>
            </article>

            <div class="category-table">
                <div class="table">
                    <nav class="panel">
                        <div class="panel-heading">
                            <div class="type">
                                <strong>Type</strong>
                            </div>
                            <div class="name">
                                <strong>Name</strong>
                            </div>
                            <div class="created-by">
                                <strong>Created by</strong>
                            </div>
                        </div>
                        <section class="section"
                                 v-if="categories.length === 0">
                            <div class="content has-text-grey has-text-centered">
                                <h1>
                                    <font-awesome-icon icon="empty-set"></font-awesome-icon>
                                </h1>
                                <p>Nothing here yet. </p>
                                <button class="button is-text"
                                        v-on:click="openModal('add')">
                                    Create a category?
                                </button>
                            </div>
                        </section>
                        <draggable v-model="categories"
                                   v-on:end="onDragEnd">
                            <transition-group>
                                <a class="panel-block is-active"
                                   v-for="category in categories"
                                   v-bind:key="category._id">
                                    <div class="type">
                                        {{ categoryTypeMap[category.type][lang] }}
                                    </div>
                                    <div class="name">
                                        <strong>{{ category.name[lang] }}</strong>
                                    </div>
                                    <div class="created-by">
                                        {{ category.createdBy }}
                                    </div>
                                    <div class="flex-2">
                                        <button class="button is-small" style="margin-right: 5px"
                                                v-on:click="editCategory(category)">
                                            <span class="icon is-small">
                                                <font-awesome-icon icon="cog"></font-awesome-icon>
                                            </span>
                                        </button>
                                        <button class="button is-small is-danger is-outlined"
                                                v-on:click="deleteCategory(category)">
                                            <span class="icon is-small">
                                                <font-awesome-icon icon="trash-alt"></font-awesome-icon>
                                            </span>
                                        </button>
                                    </div>
                                </a>
                            </transition-group>
                        </draggable>
                    </nav>
                    <div class="has-text-right">
                        {{ categories.length }} Categories
                    </div>
                </div>
            </div>
        </div>

        <b-modal has-modal-card
                 v-bind:active.sync="isCategoryModalActive">
            <modal-category target="shop"
                            v-bind:category="categoryToBeEdited">
            </modal-category>
        </b-modal>
    </section>
</template>

<script>
import draggable from "vuedraggable";
import ModalCategory from "../modals/ModalCategory";
import ToolBar from "../layouts/ToolBar";

export default {
    name: "ShopCategories",
    components: {
        ToolBar,
        draggable,
        ModalCategory
    },
    data() {
        return {
            sortableOptions: {
                chosenClass: "is-selected"
            },

            isCategoryModalActive: false,
            categoryToBeEdited: null,

            categoryTypeMap: {
                "type": {
                    "en": "Product Type",
                    "zh-hk": "產品類型",
                    "zh-cn": "产品类型"
                }
            },

            loading: false,
            edited: false,

            allowSave: false,

            lang: "en",
            categories: []
        }
    },
    computed: {},
    methods: {
        openModal(mode) {
            this.isCategoryModalActive = true;
            this.categoryToBeEdited = null;
        },

        editCategory(category) {
            this.isCategoryModalActive = true;
            this.categoryToBeEdited = category;
        },

        async deleteCategory(category) {
            this.loading = true;
            try {
                await this.$store
                    .dispatch(
                        "shop/deleteCategory",
                        category._id
                    );
            } catch (e) {
                console.error(e);
                this.loading = false;
                return;
            }
            this.loading = false;
        },

        onDragEnd() {
            this.edited = true
        },

        async save() {
            if (this.edited) {
                this.loading = true;
                let temp = this.categories.map(category => category._id);
                try {
                    await this.$store.dispatch(
                        "shop/updateShop",
                        {
                            categories: temp
                        }
                    );
                } catch (e) {
                    console.log(e)
                    this.loading = false
                    return
                }
                this.loading = false
            }
        }
    },
    mounted () {
        this.categories = this.$store.state.shop.categories;
    },
    watch: {
        "$store.state.shop.categories": {
            handler (newVal) {
                if (newVal) {
                    this.categories = newVal
                }
            },
            deep: true
        }
    }
}

</script>
<style lang="scss" scoped>
.shop-categories {
    position: relative;
    background-color: rgba(245, 245, 245, 1);

    & > .content {
        margin: 1rem;

        .category-table {
            background-color: rgba(255, 255, 255, 1);

            & > .table {
                .panel-heading {
                    font-size: 16px;
                }

                .panel-heading,
                .panel-block {
                    display: flex;
                    justify-content: stretch;

                    & > .type {
                        flex: 2;
                    }

                    & > .name {
                        flex: 3;
                    }

                    & > .created-by {
                        flex: 2;
                    }
                }
            }
        }
    }
}
</style>
